import React from "react"
import styled from "styled-components"
const DiegoCredit = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40px;
  height: 20px;
  z-index: 999;
  color: black;
  right: 15px;
  top: 5px;
  font-family: charlotte-bis;
  font-size: 14px;
  line-height: 1;
  text-align: right;
  padding: 20px 5px;
  svg {
    height: 16px;
    width: 16px;
    margin: 0 4px;
  }
  p {
    margin: 0;
    display: none;
  }
  a {
    text-decoration: none;
  }
  cursor: pointer !important;
  &:hover {
    p {
      display: initial;
    }
    width: 300px;
  }
  @media screen and (max-width: 799px) {
    display: none;
  }
`
function DiegoVector(props) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="266.08px"
      height="266.08px"
      viewBox="0 0 266.08 266.08"
      fill="back"
    >
      <g>
        <path
          d="M133.04,0c73.5,0,133.04,59.53,133.04,132.7c0,73.84-59.54,133.38-133.04,133.38S0,206.54,0,132.7
		C0,59.53,59.54,0,133.04,0z M36.25,56.87c-15.63,19.62-25.27,44.24-26.27,71.17H63.2c0.34-19.28,3.33-37.25,8.31-53.88
		C57.87,69.84,45.9,63.86,36.25,56.87z M9.98,138.02c1.33,26.27,10.64,50.56,25.94,70.18c9.64-6.99,21.61-12.65,35.25-17.29
		c-4.64-16.3-7.64-34.26-7.98-52.89H9.98z M41.91,215.52c16.3,17.97,38.25,31.27,62.86,37.25c-12.31-11.65-23.28-30.26-30.93-52.88
		C61.53,204.21,50.89,209.19,41.91,215.52z M104.77,13.3c-24.28,5.66-45.89,18.63-62.19,36.25c8.65,6,19.62,11.32,31.93,14.98
		C82.15,42.9,92.8,24.6,104.77,13.3z M128.06,128.04v-44.9c-16.97-0.32-33.27-2.65-47.57-6.65c-4.32,15.63-6.99,32.59-7.31,51.55
		H128.06z M128.06,138.02H73.18c0.32,18.29,2.65,35.26,6.99,50.22c14.3-3.99,30.59-5.99,47.89-6.65V138.02z M128.06,191.58
		c-16.3,0.66-31.6,2.65-45.57,6.31c10.3,32.27,27.94,54.22,45.57,57.87V191.58z M128.06,10.64c-17.31,3.32-34.92,24.94-45.24,56.53
		c13.97,3.66,28.94,5.66,45.24,5.99V10.64z M138.04,10.64v62.52c15.96-0.32,31.59-2.33,45.23-5.99
		C172.62,35.59,155.33,13.96,138.04,10.64z M138.04,128.04h55.21c-0.34-18.96-3.33-35.92-7.65-51.55
		c-14.3,4.32-30.6,6.32-47.56,6.65V128.04z M138.04,181.6c16.95,0.66,33.58,2.99,47.89,6.65c3.98-14.96,6.97-31.93,7.31-50.22
		h-55.21V181.6z M138.04,255.76c17.29-3.66,34.92-25.61,45.23-57.87c-13.64-3.66-29.26-5.65-45.23-6.31V255.76z M223.51,49.55
		c-16.29-17.62-37.91-30.59-62.19-36.25c11.97,11.31,22.61,29.28,30.6,50.89C203.89,60.53,214.53,55.55,223.51,49.55z
		 M161.31,252.77c24.62-5.65,46.23-18.96,62.86-37.25c-8.98-5.99-19.95-11.31-31.93-15.3C184.59,222.51,173.62,241.12,161.31,252.77
		z M256.1,128.04c-0.99-26.93-10.64-51.55-26.27-71.17c-9.98,6.99-21.62,12.97-34.92,17.29c4.32,16.63,7.31,34.6,8.31,53.88H256.1z
		 M230.16,208.21c15.3-19.62,24.62-43.91,25.94-70.18h-52.88c-1,18.63-3.66,36.91-8.31,53.22
		C208.55,195.56,220.52,201.22,230.16,208.21z"
        />
      </g>
    </svg>
  )
}

export default function Diego(props) {
  return (
    <a target="blank" href="https://www.diegoper.net">
      <DiegoCredit>
        <p>Web development by Diego Pernet</p>
        <DiegoVector />
      </DiegoCredit>
    </a>
  )
}
