/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import styled, { keyframes } from "styled-components"
import LandscapeMobile from "./LandscapeMobile"
import { Helmet } from "react-helmet"
import favicon from "../images/favicon.ico"

const PopIn = keyframes`
  from {
   opacity: 0;
  }
  to {
    opacity:1;
  }
`

const LayoutDiv = styled.div`
  /*
  opacity: 0;
  animation: ${PopIn} 0.5s 0.5s linear forwards;
*/
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 799px) {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch-action;
  }
`

const Layout = ({ children }) => {
  return (
    <>
      <link rel="icon" href={favicon} />
      <LayoutDiv siteTitle="Charlotte Louis">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Charlotte Louis</title>
          <meta
            name="description"
            content="Charlotte Louis is an Art Director based in Paris."
          />
        </Helmet>
        <LandscapeMobile id="disclaimer" />
        <main>{children}</main>
      </LayoutDiv>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
