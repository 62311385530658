import styled from "styled-components"

const StyledFooter = styled.p`
  display: flex;

  position: fixed;
  left: 20px;
  right: 20px;

  margin: 0;
  padding: 0;
  @media (min-width: 800px) {
    bottom: 0;
    height: 160px;

    flex-direction: row;
    justify-content: space-between;
    /* smaller */
    @media (max-height: 650px) {
      height: 120px;
    }
    @media (max-height: 500px) {
      height: 100px;
    }
    /* bigger */
    @media (min-height: 1100px) and (min-width: 2400px) {
      height: 170px;
    }
  }
  /* RESPONSIVE */
  @media (max-width: 799px) {
    bottom: 10px;
    flex-direction: column-reverse;
    height: 20.5vh;
  }
`

const StyledContact = styled.div`
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer !important;
    &:hover {
      font-style: italic;
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
  @media (min-width: 800px) {
    width: 460px;
    flex-wrap: wrap;
    justify-content: center;

    font-size: 29px;
    line-height: 1.1;
    cursor: pointer !important;

    #charlotte {
      margin-left: 20px;
    }
    #title {
      margin-left: 65px;
    }
    #mail {
      margin-left: 32px;
      line-height: 1.32;
    }
    @media (max-width: 1000px) {
      #charlotte {
        margin-left: 5px;
      }
      #title {
        margin-left: 25px;
      }
      #mail {
        margin-left: 8px;
      }
    }
    /* smaller */
    @media (max-height: 650px) {
      font-size: 25px;
    }
    @media (max-height: 500px) {
      font-size: 22px;
    }
    /* bigger */
    @media (min-height: 1100px) and (min-width: 2400px) {
      font-size: 34px;
    }
  }
  /* RESPONSIVE */

  @media (max-width: 799px) {
    justify-content: flex-end;
    flex-direction: column;
    font-size: 2.2vh;
    line-height: 1.2;
    height: 9vh;
    width: 100%;
    #charlotte {
      margin-left: 2vh;
    }
    #title {
      margin-left: 7.2vh;
    }
    #mail {
      margin-left: 4vh;
    }
  }
`
// GALLRY + NUMBER
const StyledNavigation = styled.p`
  @media (min-width: 800px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    height: 100%;

    p {
      margin: 0;
      padding: 0;
    }
    z-index: 999;
  }
  /* RESPONSIVE */

  @media (max-width: 799px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    width: 100%;
  }
`
const StyledGalleryList = styled.p`
  font-family: Charlotte;
  span {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none !important;
    color: black !important;
    &:hover {
      font-style: italic;
    }
  }
  @media (min-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    box-sizing: border-box;

    font-size: 20px;
    line-height: 1.2;
    z-index: 999;
    width: 240px;
    @media (min-width: 1200px) {
      width: 330px;
    }
    @media (max-width: 1000px) {
      width: 200px;
    }
    .mobile {
      display: none;
    }
    /* smaller */
    @media (max-height: 650px) {
      font-size: 17px;
    }
    @media (max-height: 500px) {
      font-size: 15.5px;
    }
    /* bigger */
    @media (min-height: 1100px) and (min-width: 2400px) {
      font-size: 24px;
      width: 400px;
    }
  }
  /* RESPONSIVE */
  @media (max-width: 799px) {
    text-align: left !important;
    font-size: 2.3vh;
    line-height: 1.2;
    letter-spacing: -0.02vh;
    margin: 0;
    padding: 0;
    width: 12vh;
    &#about-list {
      width: 16vh;
    }
    a {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
`

const StyledNumber = styled.div`
  display: none;
  z-index: 10;
  pointer-events: none;
  @media (min-width: 800px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;

    font-family: CharlotteBis;
    font-style: italic;
    font-size: 130px;
    line-height: 130px;
    transform: translateY(22px);
    width: 180px;
    position: relative;
    /* smaller */
    @media (max-height: 650px) {
      font-size: 115px;
      line-height: 115px;
      transform: translateY(8px);
      @media (max-height: 500px) {
        font-size: 105px;
        line-height: 105px;
        transform: translateY(2px);
      }
      /* bigger */
    }
    @media (min-height: 1100px) and (min-width: 2400px) {
      font-size: 155px;
      line-height: 155px;
      width: 200px;
      transform: translateY(18px);
    }
  }
`

export {
  StyledFooter,
  StyledGalleryList,
  StyledContact,
  StyledNavigation,
  StyledNumber,
}
