import styled, { keyframes } from "styled-components"
import "@carousel/slick.css"
import "@carousel/slick-theme.css"
import "./carousel/slick.css"
import "./carousel/slick-theme.css"
const FadeIn = keyframes`
  from {
   opacity: 0;
  }
  to {
    opacity:1;
  }
`

const WrapperTemplate = styled.div`
  /*
  opacity: 0;
  animation: ${FadeIn} 1s 0.4s linear forwards;
  */

  position: fixed;
  top: 48px;
  left: 40px;
  right: 40px;
  z-index: 99;
  @media (min-width: 800px) and (max-height: 650px) {
    top: 4vh;
  }

  &#index,
  &#about {
    display: block;
  }
  &.gallery {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  .slick-next {
    cursor: e-resize !important;
  }
  .slick-prev {
    cursor: w-resize !important;
  }

  .cover-pic {
    opacity: 0;
    &#about-pic {
      opacity: 1;
    }
    &:not(#about-pic) {
      pointer-events: none;
    }
    @media screen and (max-width: 799px) {
      display: none;
    }
  }
  /* Responsive phone */
  @media screen and (max-width: 799px) {
    top: 18px;
    left: 20px;
    right: 20px;
  }
`

const StyledPicture = styled.div`
  /* background-color: red; */
  margin: 0;
  display: block;
  z-index: 999;
  background-color: white !important;

  .image {
    width: 100%;
  }
  img {
    @media screen and (min-width: 800px) {
      height: 70vh;
      object-position: top center;
      /*
      @media (max-height: 700px) {
        height: calc(100vh -240px);
      } */
    }
  }
  &.cover-pic {
    position: absolute;
    width: 100%;
    display: block;
  }
  /* mobile */
  @media screen and (max-width: 799px) {
    img {
      height: 60vh;
      object-position: top;
    }
  }
`

export default WrapperTemplate
export { StyledPicture }
