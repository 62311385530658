import React from "react"
import { Link } from "gatsby"
import { StyledContact } from "./StyledFooter"

function ConditionalLink(props) {
  const about = props.about
  if (about) {
    return (
      <Link to="/">
        <p id="charlotte">
          <span className="charlotte-bis">C</span>harlotte{" "}
          <span className="charlotte-bis">L</span>ouis
        </p>
      </Link>
    )
  }
  return (
    <Link to="/">
      <p id="charlotte">
        <span className="charlotte-bis">C</span>harlotte{" "}
        <span className="charlotte-bis">L</span>ouis
      </p>
    </Link>
  )
}

const Contact = props => (
  <StyledContact>
    <ConditionalLink about={props.about} />
    <a id="title">
      <span className="charlotte-bis">A</span>rt{" "}
      <span className="charlotte-bis">D</span>irection{"\u2009"}+{"\u2009"}
      <span className="charlotte-bis">P</span>hotography
    </a>
    <a id="mail" href="mailto:hello@charlotte-louis.com">
      hello<span className="charlotte-bis">@</span>charlotte-louis.com
    </a>
  </StyledContact>
)

export default Contact
