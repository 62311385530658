import React from "react"
import styled from "styled-components"

const StyledLandscape = styled.div`
  display: none;
  @media (orientation: landscape) and (max-height: 349px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    background-color: white;
    p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0;
    }

    /* Responsive */
  }
  z-index: 999;
  /* mobile */
`

const FixedBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: -1;
`

const LandscapeMobile = () => (
  <StyledLandscape id="disclaimer">
    <FixedBackground />
    <p>For a better experience please switch to portrait mode</p>
  </StyledLandscape>
)

export default LandscapeMobile
