import { Link } from "gatsby"
import React from "react"
import { StyledGalleryList } from "./StyledFooter"

const GalleryList = props => (
  <>
    <StyledGalleryList>
      <a>
        <Link
          id="link-one"
          className="gallery-link desktop"
          onMouseOver={props.oneHover}
          onMouseLeave={props.exitHover}
          to="/horizons"
        >
          <span className="charlotte-bis">H</span>orizons
        </Link>
        <Link id="link-one" className="gallery-link mobile" to="/horizons">
          <span className="charlotte-bis">H</span>orizons
        </Link>
      </a>
      <a>
        <Link
          id="link-two"
          className="gallery-link desktop"
          onMouseOver={props.twoHover}
          onMouseLeave={props.exitHover}
          to="/origins"
        >
          <span className="charlotte-bis">O</span>rigins
        </Link>
        <Link id="link-two" className="gallery-link mobile" to="/origins">
          <span className="charlotte-bis">O</span>rigins
        </Link>
      </a>
      <a>
        <Link
          id="link-three"
          className="gallery-link desktop"
          onMouseOver={props.threeHover}
          onMouseLeave={props.exitHover}
          to="/anecdotes"
        >
          <span className="charlotte-bis">A</span>necdotes
        </Link>
        <Link id="link-three" className="gallery-link mobile" to="/anecdotes">
          <span className="charlotte-bis">A</span>necdotes
        </Link>
      </a>
      <a>
        <Link
          id="link-four"
          className="gallery-link desktop"
          onMouseOver={props.fourHover}
          onMouseLeave={props.exitHover}
          to="/goodbye"
        >
          <span className="charlotte-bis">G</span>oodbye
        </Link>
        <Link id="link-four" className="gallery-link mobile" to="/goodbye">
          <span className="charlotte-bis">G</span>oodbye
        </Link>
      </a>
    </StyledGalleryList>
  </>
)

const InfosList = props => (
  <StyledGalleryList id="about-list">
    <Link to="/">
      <span>Art Director</span>
      <br />
      <span>Photographer</span>
      <br />
      <br />
      <span>Based in Paris</span>
    </Link>
  </StyledGalleryList>
)

export default GalleryList
export { InfosList }
